import React from "react"
import { graphql } from "gatsby"
import * as transformer from "../gatsby/transformers/productTaxonomyTransformer"
import ProductTaxonomyPage from "../social-supermarket/pages/product-taxonomy/ProductTaxonomyPage"
import { filterByVisibility } from "../social-supermarket/util/productUtil"

const ImpactTemplate = ({
  data: { wpImpact, allWpImpact },
  pageContext: { products, queryId },
}) => {
  wpImpact.impacts = allWpImpact
  const taxonomy = transformer.fromGatsby(wpImpact, "impact")
  taxonomy.products = filterByVisibility(products, "Marketplace")
  return (
    <ProductTaxonomyPage
      taxonomy={taxonomy}
      collectionName={`Impact:  ${taxonomy.name}`}
      queryId={queryId}
    />
  )
}

export default ImpactTemplate

export const query = graphql`
  query ($id: String) {
    wpImpact(id: { eq: $id }) {
      slug
      name
      description
    }
    allWpImpact {
      nodes {
        id
        slug
        name
      }
    }
  }
`
